<template>
  <div class="flex flex-col h-full m-4">
    <div class="inline-flex items-center my-4">
      <router-link to="/auth/login">
        <svg
          class="w-6 h-6 fill-current "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 512"
        >
          <path
            d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
          />
        </svg>
      </router-link>
      <h1 class="uppercase">
        Privacy Policy
      </h1>
    </div>
    <div class="p-4 mb-4 text-sm text-gray-700 bg-gray-100 rounded-md">
      <p class="my-2">
        Adanya Kebijakan Privasi ini adalah komitmen nyata dari PT.Bumi Inovasi
        Ganapatih untuk menghargai dan melindungi setiap data atau informasi
        pribadi Pengguna situs CHECKIN. Kebijakan Privasi ini (beserta
        syarat-syarat penggunaan dari situs CHECKIN sebagaimana tercantum dalam
        "Syarat & Ketentuan" dan informasi lain yang tercantum di Situs)
        menetapkan dasar atas perolehan, pengumpulan, pengolahan,
        penganalisisan, penampilan, pembukaan, dan/atau segala bentuk
        pengelolaan yang terkait dengan data atau informasi yang Pengguna
        berikan kepada CHECKIN atau yang kami kumpulkan dari Pengguna, termasuk
        data pribadi Pengguna, baik pada saat Pengguna melakukan pendaftaran di
        Situs, mengakses Situs, maupun mempergunakan layanan-layanan pada Situs
        (selanjutnya disebut sebagai "data").
      </p>
      <p class="my-2">
        Dengan mengakses dan/atau mempergunakan layanan CHECKIN, Pengguna
        menyatakan bahwa setiap data Pengguna merupakan data yang benar dan sah,
        serta Pengguna memberikan persetujuan kepada kami untuk memperoleh,
        mengumpulkan, menyimpan, mengelola dan mempergunakan data tersebut
        sebagaimana tercantum dalam Kebijakan Privasi maupun Syarat dan
        Ketentuan.
      </p>
    </div>
  </div>
</template>
